<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="isBatch ? '批量发货' : '发货信息'"
    :visible.sync="show"
    width="1000px"
  >
    <div class="content">
      <p class="tipsWrap" v-if="isBatch">
        当前选中{{ multipleSelection.length }}笔订单，请设置选中发货车辆
      </p>
      <el-table
        v-loading="loading"
        :data="tableData"
        :height="300"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseUnit"
          label="采购单位"
        >
        </el-table-column>
        <el-table-column
          prop="supplyPrice"
          label="采购单价(元)"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="采购数量"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="金额小计(元)">
          <template slot-scope="{ row }">
            <span>{{ Number(row.payPrice || 0).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isBatch"
          show-overflow-tooltip
          width="155"
          label="发货数量"
        >
          <template slot-scope="{ row }">
            <el-input-number
              v-if="!isDistribution"
              :min="1"
              :max="row.modifyNum || row.goodsNum"
              size="mini"
              v-model="row.supplyDeliverNum"
            >
            </el-input-number>
            <span v-else>{{ row.supplyDeliverNum }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-form
        style="margin-top: 20px; width: 300px"
        :disabled="isDistribution"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item v-if="!isBatch" label="订单编号">
          <span>{{ form.purchaseNo }}</span>
        </el-form-item>

        <el-form-item v-if="!isBatch" label="配送方式" prop="type">
          <el-select v-model="form.type" clearable @change="typeChange">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <template v-if="form.type === 1">
          <el-form-item label="车牌号" prop="carNo">
            <el-select
              v-model="form.carNo"
              filterable
              placeholder="请选择车牌号"
            >
              <el-option
                v-for="item in carList"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <!-- <span>{{ item.label }}</span>
                <span style="margin-left: 6px; color: #8492a6; font-size: 13px"
                  >({{ item.name }})</span
                > -->
              </el-option>
              <div slot="empty" class="emptyWrap">
                <p>未找到车辆信息？</p>
                <!-- <div>
                  <el-button type="text" @click="$router.push('/carManage')"
                    >点击去添加</el-button
                  >
                </div> -->

                <div>
                  <el-button type="text" @click="addVisible = true"
                    >点击添加</el-button
                  >
                </div>
              </div>
            </el-select>
          </el-form-item>

          <el-form-item label="联系电话" prop="phone">
            <el-input
              type="number"
              v-model="form.phone"
              placeholder="请输入联系电话"
            />
          </el-form-item>

          <el-form-item label="联系人" prop="consignee">
            <el-input v-model="form.consignee" placeholder="请输入联系人" />
          </el-form-item>
        </template>

        <template v-if="form.type === 2">
          <el-form-item label="快递公司" prop="logisticsCompany">
            <el-input
              v-model.trim="form.logisticsCompany"
              placeholder="请输入快递公司"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入联系电话" />
          </el-form-item>
        </template>

        <template v-if="form.type === 3">
          <el-form-item label="快递公司" prop="logisticsCompany">
            <el-input
              v-model.trim="form.logisticsCompany"
              placeholder="请输入快递公司"
            />
          </el-form-item>

          <el-form-item label="快递单号" prop="logisticsNo">
            <el-input v-model="form.logisticsNo" placeholder="请输入快递单号" />
          </el-form-item>
        </template>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        v-if="!isDistribution"
        type="primary"
        @click="submit"
        :loading="btnLoading"
        >确定</el-button
      >
    </span>

    <AddModal
      v-if="addVisible"
      @getData="getCarList"
      :visible.sync="addVisible"
      :groupList="groupList"
      :current-item="currentItem"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import AddModal from './AddModal.vue'
export default {
  props: ['isDistribution', 'multipleSelection'],
  mixins: [dialogCommonParams],
  data() {
    const _this = this

    return {
      rules: {
        type: [{ required: true, trigger: 'blur', message: '请选择配送方式' }],
        logisticsCompany: [
          { required: true, trigger: 'blur', message: '请输入快递公司' }
        ],
        logisticsNo: [
          { required: true, trigger: 'blur', message: '请输入快递单号' }
        ],
        carNo: [{ required: true, trigger: 'blur', message: '请输入车牌号' }],
        consignee: [
          { required: true, trigger: 'blur', message: '请输入联系人' }
        ],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!_this.$utils.isPhone(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      form: {
        type: 3,
        purchaseNo: '',
        logisticsCompany: '',
        logisticsNo: ''
      },
      addVisible: false,
      tableData: [],
      carList: [],
      groupList: [],
      typeList: [
        {
          label: '快递配送',
          value: 3
        },
        {
          label: '整车物流',
          value: 1
        },
        {
          label: '零单物流',
          value: 2
        }
      ],
      file: null,
      btnLoading: false,
      loading: false,
      params: {}
    }
  },
  created() {
    this.getCarGroupList()
    this.getCarList()
    this.form.purchaseNo = this.currentItem.purchaseNo || ''

    if (this.isDistribution) {
      this.getPurchaseDetail(this.currentItem.id)
    } else {
      if (!this.isBatch) {
        this.getDetail()
      } else {
        this.getBatchData()
        this.form.type = 1
      }
    }
  },
  components: {
    AddModal
  },
  computed: {
    isBatch() {
      return this.multipleSelection.length && !this.currentItem.id
    }
  },
  methods: {
    async getCarGroupList() {
      try {
        const res = await this.$api.carManage.getCarGroupList()

        let group = res.group || []

        group.push({
          groupName: '未分组',
          id: ''
        })

        this.groupList = res.group.map(item => ({
          label: item.groupName,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCarList() {
      try {
        const res = await this.$api.carManage.getCarAllList()

        this.carList = res.vehicles.map(item => ({
          label: item.licensePlate,
          name: item.vehicleName,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },

    async getBatchData() {
      try {
        const res = await this.$api.orderList.getBatchData(
          this.multipleSelection
        )
        this.tableData = (res.details || []).map(item => {
          item.supplyPrice = item.inPrice
          return item
        })
      } catch (e) {
        console.log(e)
      }
    },

    async getDetail() {
      try {
        this.loading = true

        const res = await this.$api.orderList.getOrderDetail({
          limit: 1000,
          page: 1,
          purchaseId: this.currentItem.id
        })
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          return {
            supplyDeliverNum:
              item.supplyDeliverNum || item.modifyNum || item.goodsNum || '-',
            goodsName: item.goods && item.goods.goodsName,
            barNo: item.goods && item.goods.barNo,
            purchaseUnit: item.goods && item.goods.purchaseUnit,
            supplyPrice: item.goods && item.goods.supplyPrice,
            goodsNum: item.goodsNum,
            payPrice: item.payPrice,
            id: item.id,
            goodsId: item.goodsId
          }
        })
        this.totalCount = res.page.totalCount
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getPurchaseDetail(id) {
      try {
        this.loading = true
        const res = await this.$api.orderList.getPurchaseDetail(id)

        this.form = {
          type: res.info.type,
          purchaseNo: res.info.purchaseNo,
          logisticsCompany: res.info.logisticsCompany,
          address: res.info.address,
          carNo: res.info.carNo,
          consignee: res.info.consignee,
          logisticsNo: res.info.logisticsNo,
          phone: res.info.phone
        }

        this.tableData = (res.info.detailList || []).map(item => {
          const { goodsName, barNo, unit, supplyPrice } = item.goods

          item.goodsName = goodsName
          item.barNo = barNo
          item.unit = unit
          item.supplyPrice = supplyPrice

          item.supplyDeliverNum =
            (item.receivingGoods && item.receivingGoods.supplyDeliverNum) ||
            item.goodsNum ||
            '-'

          return item
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    typeChange() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          const sendData = this.form

          if (!this.isBatch) {
            sendData.detailList = this.tableData
          } else {
            sendData.purchaseNoList = this.multipleSelection
          }

          try {
            this.isBatch
              ? await this.$api.orderList.sendBatchProduct(sendData)
              : await this.$api.orderList.sendProduct(sendData)

            this.$message.success('发货成功')
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}

.tipsWrap {
  margin-bottom: 16px;
  font-weight: bold;
}

.emptyWrap {
  padding: 10px;
  text-align: center;
}
</style>
