<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="商品列表"
    :visible.sync="show"
    width="600px"
  >
    <div class="formWrap">
      <el-form inline ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>

        <el-form-item label="条形码" prop="barNo">
          <el-input placeholder="条形码" v-model="params.barNo" />
        </el-form-item>

        <el-button type="primary" plain @click="getData"> 查询 </el-button>
        <!-- <el-button plain @click="parentClear">重置</el-button> -->
      </el-form>
    </div>
    <el-table
      ref="selectTable"
      v-loading="loading"
      :data="tableData"
      max-height="300"
      highlight-current-row
      @current-change="handleCurrentChange"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      />
      <el-table-column show-overflow-tooltip prop="barNo" label="条形码" />
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        :total="totalCount"
        :page-size="params.limit"
        :current-page="params.page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChange"
        @current-change="currentChange"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
      <el-button
        :disabled="!multipleSelection.length"
        type="primary"
        @click="saveHandler"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  props: ['selectGoodList'],
  mixins: [dialogCommonParams],
  data() {
    return {
      totalCount: 0,
      loading: false,
      btnLoading: false,
      params: {
        limit: 20,
        page: 1,
        goodsName: '',
        barNo: ''
      },
      tableData: [
        {
          goodsName: '商品1',
          barNo: 1
        },
        {
          goodsName: '商品2',
          barNo: 2
        }
      ],
      multipleSelection: []
    }
  },
  mounted() {
    // if (this.selectGoodList.length) {
    //   this.$nextTick(() => {
    //     this.selectGoodList.forEach((row, index) => {
    //       this.$refs.selectTable.toggleRowSelection(this.tableData[index])
    //     })
    //   })
    // }

    this.getData()
  },
  methods: {
    handleCurrentChange(val) {
      this.multipleSelection = [val]
    },
    sizeChange(size) {
      this.params.limit = size
      this.getData()
    },
    currentChange(page) {
      this.params.page = page
      this.getData()
    },
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.productList.getProductList(params)

        if (res.page && res.page.list) {
          this.tableData = res.page.list
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async saveHandler() {
      try {
        this.btnLoading = true

        this.$emit('getData', this.multipleSelection)
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.formWrap {
  display: flex;
  justify-content: space-between;
  height: 69px;
}
.pagination-wrap {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-table__row {
  cursor: pointer;
}
</style>
