<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="批量调整"
    :visible.sync="show"
    width="450px"
  >
    <p class="tipsHeader">选中的订单商品数量为：{{ orderGoodsTotalNum }}</p>
    <el-form
      @submit.native.prevent
      size="small"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="当前库存：" prop="stock">
        <el-input
          style="width: 200px"
          placeholder="请输入当前库存数量"
          v-model="form.stock"
          @blur="blurHandler"
          @input="
            val => {
              form.stock = onlyNum(val)
            }
          "
        ></el-input>
      </el-form-item>

      <el-form-item label="下调比例：" prop="reductionRatio">
        <el-input
          style="width: 200px"
          placeholder="请输入下调比例"
          type="number"
          v-model="form.reductionRatio"
        ></el-input
        >&nbsp;%
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['multipleSelection', 'currentId'],
  data() {
    return {
      btnLoading: false,
      rules: {
        stock: [
          {
            required: true,
            message: '请输入当前库存数量',
            trriger: 'blur'
          }
        ],
        reductionRatio: [
          {
            required: true,
            message: '请输入下调比例',
            trriger: 'blur'
          }
        ]
      },
      form: {
        stock: '',
        reductionRatio: ''
      }
    }
  },

  computed: {
    orderGoodsTotalNum() {
      return this.multipleSelection.reduce((num, item) => {
        num += +item.modifyNum || +item.goodsNum
        return num
      }, 0)
    }
  },

  methods: {
    blurHandler() {
      this.form.reductionRatio = Number(
        ((this.orderGoodsTotalNum - Number(this.form.stock)) /
          this.orderGoodsTotalNum) *
          100
      ).toFixed(2)
    },
    formSubmit() {
      this.loading = true

      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.form.reductionRatio < 0) {
            this.$message.warning('当前库存充足，无需下调订单商品数量')
            return
          }

          try {
            await this.$api.orderList.batchAdjust({
              detailIdList: this.multipleSelection.map(item => item.detailId),
              reductionRatio: this.form.reductionRatio
            })

            this.$message.success('保存成功')
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tipsHeader {
  text-indent: 18px;
  margin-bottom: 16px;
}
</style>
