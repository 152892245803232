<template>
  <el-dialog
    append-to-body
    class="BatchEditModal"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="批量修改订单"
    :visible.sync="show"
    width="1200px"
  >
    <div class="selectGoodWrap">
      <div>
        选择需调整的商品：
        <el-button @click="selectGoodsVisible = true" size="mini" type="text">
          {{ !selectGoodList.length ? '选择商品' : '修改' }}</el-button
        >
      </div>

      <div class="itemWrap">
        <span class="item" v-for="(item, index) in selectGoodList" :key="index">
          {{ item.goodsName }}
        </span>
      </div>
    </div>

    <h2 style="margin: 20px 0 10px" v-if="tableData.length">
      对包含以上商品的订单进行调整：
    </h2>

    <div class="formWrap">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="订单类型" prop="purchaseType">
          <el-select v-model="params.purchaseType" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="收货地区" prop="address">
          <el-input placeholder="收货地区" v-model="params.address" />
        </el-form-item> -->
        <el-form-item label="门店名称" prop="storeName">
          <el-input placeholder="门店名称" v-model="params.storeName" />
        </el-form-item>
        <el-form-item label="门店分组" prop="groupManageId">
          <el-select v-model="params.groupManageId" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-button type="primary" plain @click="getData(true)">
          查询
        </el-button>
        <el-button plain @click="parentClear">重置</el-button>
        <el-button
          type="primary"
          :disabled="!multipleSelection.length"
          @click="batchAdjust"
        >
          批量调整
        </el-button>
      </el-form>
    </div>

    <div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        :max-height="350"
        border
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNo"
          label="订单编号"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="订单类型">
          <template slot-scope="{ row }">
            <span>{{ row.purchaseType == 1 ? '预定订单' : '普通订单' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="storeName" label="门店名称"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="storeGroup"
          label="门店分组"
        />
        <el-table-column
          width="120"
          show-overflow-tooltip
          prop="storeAddress"
          label="收货信息"
        />

        <el-table-column show-overflow-tooltip min-width="200" label="商品信息">
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p>
                <span class="label">商品名称:</span>
                <span class="maxText">{{ row.goodsName || '-' }}</span>
              </p>
              <p>
                <span class="label">条形码:</span>
                <span class="maxText">{{ row.barNo || '-' }}</span>
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="订单原数量"
        >
        </el-table-column>

        <el-table-column width="160" show-overflow-tooltip label="调整后数量">
          <template slot-scope="{ row }">
            <el-input-number
              v-if="row.edit"
              v-model="row.modifyNum"
              size="mini"
              :min="0"
              :max="row.goodsNum"
            >
            </el-input-number>

            <span v-else>{{ row.modifyNum }}</span>
          </template>
        </el-table-column>

        <el-table-column width="120" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-if="!row.edit"
              @click="row.edit = true"
              size="mini"
              type="text"
            >
              修改数量</el-button
            >
            <el-button v-else @click="saveChange(row)" size="mini" type="text">
              保存修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>

    <BatchAdjustModal
      v-if="batchAdjustVisible"
      :visible.sync="batchAdjustVisible"
      :currentId="currentId"
      :currentItem="currentItem"
      :multipleSelection="multipleSelection"
      @getData="getData(true)"
    />

    <SelectGoodsModal
      v-if="selectGoodsVisible"
      :visible.sync="selectGoodsVisible"
      :currentId="currentId"
      :currentItem="currentItem"
      :selectGoodList="selectGoodList"
      @getData="selectGoodHandler"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import BatchAdjustModal from './BatchAdjustModal'
import SelectGoodsModal from './SelectGoodsModal'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      editPriceVisible: false,
      batchAdjustVisible: false,
      selectGoodsVisible: false,
      currentId: null,
      tableData: [],
      typeList: [
        {
          label: '普通订单',
          value: '0'
        },
        {
          label: '预定订单',
          value: '1'
        }
      ],
      groupList: [],
      selectGoodList: [],
      supplyPrice: '',
      multipleSelection: [],
      totalCount: 0,
      params: {
        purchaseType: '',
        // address: '',
        storeName: '',
        groupManageId: '',
        date: [],
        limit: 20,
        page: 1
      }
    }
  },
  components: {
    BatchAdjustModal,
    SelectGoodsModal
  },
  created() {
    this.getGroupList()
  },
  methods: {
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    selectGoodHandler(val) {
      this.selectGoodList = val
      this.getData()
    },
    batchAdjust() {
      this.batchAdjustVisible = true
    },
    async saveChange(row) {
      try {
        await this.$api.orderList.saveChagne({
          detailId: row.detailId,
          goodsNum: row.modifyNum
        })
      } catch (e) {
        console.log(e)
      } finally {
        row.edit = false
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },

    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endDate = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }

        params.goodsId = this.selectGoodList[0].id

        const { date, ...sendData } = params

        const res = await this.$api.orderList.getBatchOrderList(sendData)

        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(_ => {
            _.edit = false
            return _
          })
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.BatchEditModal .el-dialog__body {
  padding: 0 20px !important;
}

.BatchEditModal .el-dialog {
  margin-top: 5vh !important;
}

.BatchEditModal .el-dialog .el-form-item {
  margin-bottom: 5px !important;
}

.formWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 69px;
}

.infoWrap {
  p {
    display: flex;
    span {
      width: 60px;
    }
  }
}

.itemWrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .item {
    margin-left: 10px;
    color: #666;
  }
}
</style>
